<template>
  <base-section
    id="our-works"
    space="36"
  >
    <v-container class="fill-height px-4 pt-6">
      <v-responsive
        class="d-flex align-center mx-auto"
        height="100%"
        max-width="700"
        width="100%"
      >
        <div class="d-flex justify-center">
          <base-section-heading
            :title="$t('our_works')"
            style="width: max-content"
          />
        </div>
        <div class="sub_title justify-center mt-6">
          <base-subheading
            :title="$t('description_our_works')"
            weight="medium"
            size="text-h6"
            align="center"
          ></base-subheading>
        </div>
      </v-responsive>
      <v-container
        v-if="isLoading"
        fluid
      >
        <v-row>
          <v-col
            v-for="n in 2"
            :key="n"
            cols="12"
            md="6"
          >
            <v-skeleton-loader
              class="mx-auto"
              max-width="300"
              type="card"
            />
          </v-col>
        </v-row>
      </v-container>
      <v-container
        v-if="!isLoading"
        fluid
      >
        <v-row>
          <v-col
            v-for="(card, index) in slicePortfolio()"
            :key="index"
            sm="12"
            class="d-flex child-flex"
            md="12"
            lg="3"
            cols="12"
          >
            <v-hover v-slot:default="{ hover }">
              <v-card
                class="mx-auto"
                :to="{ name: 'PortfolioPage', params: { id: card.id } }"
              >
                <base-img
                  :src="siteUrl + card.main_icon"
                  aspect-ratio="1"
                  max-height="450"
                >
                  <v-container
                    v-show="!hover"
                    fluid
                    style="height: 100%;display: flex;flex-direction: column-reverse;justify-content: space-between;align-content: stretch;"
                    class="fill-height align wrapper"
                  >
                    <v-container>
                      <v-row>
                        <div
                          v-for="stack in card.stacks"
                          :key="stack.title"
                          style="background-color: transparent !important;"
                          class=" px-2 mb-2"
                        >
                          <img
                            :src="siteUrl + stack.icon"
                            :height="$vuetify.breakpoint.width >= 768 ? '42px' : '27px'"
                            :width="$vuetify.breakpoint.width >= 768 ? '42px' : '27px'"
                          >
                        </div>
                      </v-row>
                      <base-subheading
                        :title="card.title"
                        size="text-h4"
                        class=""
                      ></base-subheading>
                    </v-container>
                  </v-container>
                  <v-expand-transition>
                    <div
                      v-if="hover"
                      class="d-flex transition-fast-in-fast-out v-card--reveal text-h2 white--text"
                      style="height: 100%;"
                    >
                      <v-img
                        :src="require('@/assets/777.png')"
                      >
                        <v-container style="height: 100%;display: flex;flex-direction: column;justify-content: space-between;align-content: stretch;">
                          <v-container>
                            <div class="portfolio_card">
                              <base-subheading
                                :title="card.title"
                                align="left"
                                weight="400"
                                class=" mb-0"
                                :class="$vuetify.breakpoint.width >= 768 ? 'text-h4' : 'text-h5'"
                              ></base-subheading>
                              <base-subheading
                                class="white--text mt-1"
                                :class="$vuetify.breakpoint.width >= 768 ? 'text-h6' : 'text-caption'"
                                :title="card.description"
                                weight="medium"
                                style="white-space: pre-line;line-height: 1.1 !important;"
                                align="left"
                                center
                              ></base-subheading>
                            </div>
                          </v-container>
                          <v-container class="d-flex justify-center">
                            <v-btn
                              class="white--text btn_border_show "
                              width="100%"
                              height="60px"
                            >
                              <v-container class="fill-height px-4 ">
                                <v-row>
                                  <v-col
                                    cols="6"
                                    md="6"
                                  >
                                    <div class="d-flex align-center">
                                      <h4>
                                        More
                                      </h4>
                                    </div>
                                  </v-col>
                                  <v-col
                                    cols="6"
                                    md="6"
                                  >
                                    <div
                                      class="d-flex justify-end "
                                    >
                                      <div
                                        class="block_arrow"
                                      >
                                        <v-img
                                          src="@/assets/btn_arrow.png"
                                        />
                                      </div>
                                    </div>
                                  </v-col>
                                </v-row>
                              </v-container>
                            </v-btn>
                          </v-container>
                        </v-container>
                      </v-img>
                    </div>
                  </v-expand-transition>
                </base-img>
              </v-card>
            </v-hover>
          </v-col>
        </v-row>
      </v-container>
      <div
        v-if="isNeedShowMore()"
        class="d-flex justify-center mx-auto"
      >
        <v-btn
          width="100%"
          min-height="60"
          class="btn_more mt-lg-12"
          @click="clickShowMore()"
        >
          {{ $t('showMore') }}
        </v-btn>
      </div>
    </v-container>
  </base-section>
</template>

<script>
  import { siteUrl } from './../../config'
  import { mapGetters } from 'vuex'
  import BaseSectionHeading from '../../components/base/SectionHeading'
  import BaseSubtitle from '../../components/base/Subtitle'
  export default {
    name: 'SectionOurWorks',
    components: { BaseSectionHeading, BaseSubtitle },
    data: () => ({
      siteUrl: siteUrl,
      rows: 2,
      ourWorks: [
        {
          main_icon: require('@/assets/bkground_portfolio_default.png'),
          stack_icon: 'Kotlin',
        },
        {
          main_icon: require('@/assets/bkground_portfolio.png'),
          stack_icon: 'Kotlin',
        },
      ],
    }),

    computed: mapGetters({
      isLoading: 'portfolio/isLoading',
      portfolios: 'portfolio/portfolios',
    }),
    mounted () {
      this.$store.dispatch('portfolio/fetchPortfolios')
    },
    methods: {
      slicePortfolio () {
        const minIndex = Math.min(this.portfolios.length, this.rows * 2)
        console.log('minIndex: ' + minIndex + 'this.portfolios.length :' + this.portfolios.length)
        return this.portfolios.slice(0, minIndex)
      },
      isNeedShowMore () {
        return (this.rows * 2 < this.portfolios.length)
      },
      clickShowMore () {
        this.rows++
      },
    },
  }
</script>
<style lang="sass">
  .block_arrow
    width: 72px
  .btn_border_show
    border: 2px solid #F2F2F2
  .sub_title
    width: 100%
  .v-card--reveal
    bottom: 0
    opacity: 1 !important
    position: absolute
    width: 100%
  .btn_card
    vertical-align: bottom
    display: inline-block
</style>
